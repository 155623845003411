













































































import mixins from 'vue-typed-mixins'
import moment from 'moment'

import Oauth from '@/oauth'
import Http from '@/http'
import {
    formatLocalDate
} from '@/modules/date'

import Routes from '@/constants/routes'
import Forms from '@/constants/forms'
import Formats from '@/constants/formats'
import { Institution } from '@/types/index'
import { Location } from 'vue-router/types/router'
import { ToastObject } from 'vue-toasted/types/index'

import institutionMixin from '@/mixins/institutionMixin'
import currentUserMixin from '@/mixins/currentUserMixin'
import residentMixin from '@/mixins/residentMixin'

import {
    HCard,
    HBtn,
    HRow,
    HCol,
    HIcon,
    HPopin
} from '@happytal/bo-ui-library'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import SeparatorLine from '@/components/SeparatorLine.vue'
import TutorSelector from '@/components/selectors/TutorSelector.vue'
import DeactivateResidentPopin from './components/DeactivateResidentPopin.vue'

import { ResidentDto } from '@happytal/happydom-api'

interface Form {
    name: string,
    id: string
}

export default mixins(residentMixin, institutionMixin, currentUserMixin).extend({
    components: {
        ResidentHeader,
        SeparatorLine,
        TutorSelector,
        HCard,
        HBtn,
        HRow,
        HCol,
        HIcon,
        DeactivateResidentPopin
    },

    data() {
        return {
            tutorId: '',
            tutorIdBackup: '',
            tutorSelectedId: '',
            isChangesAreSaved: true,
            popinSaveTutor: false,
            residentStatus: {},
            deactivateResidentOpen: false
        }
    },

    watch: {
        $_resident(resident: ResidentDto): void {
            if (resident) {
                this.$vs.loading.close()
            }
        }
    },

    computed: {
        residentInstitution(): Institution | null {
            return this.$_resident ? this.$_getInstitutionById(this.$_resident.institutionId) : null
        },
        residentInstitutionName(): string {
            return this.residentInstitution ? this.residentInstitution.name : '-'
        },
        residentCreationDate(): string {
            const date = this.$_resident?.createdOnUtc
            return formatLocalDate(date, Formats.DisplayDate)
        },
        residentInstitutionFullAddress(): string {
            if (this.residentInstitution) {
                const address = this.residentInstitution.address.address1 || '-'
                const zipcode = this.residentInstitution.address.zipPostalCode || '-'
                const city = this.residentInstitution.address.city || '-'
                return `${address}, ${zipcode} ${city}`
            }
            return '-'
        },
        residentContactPhone(): string {
            return this.$_resident?.contactPhoneNumber || this.residentInstitution?.address?.phoneNumber || ''
        },
        residentContactEmail(): string {
            return this.$_resident?.contactEmail || this.residentInstitution?.address?.email || ''
        },
        updateResidentLink(): Location {
            return {
                name: Routes.UpdateResident,
                params: {
                    resident_id: this.$_residentId
                }
            }
        },
        canEditTutor(): boolean {
            return this.$acl.check('canUpdateResident')
        },
        canDeactivateResident(): boolean {
            return !this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily'
            ])
        },
        linkOfResidentPayment(): Location {
            return {
                name: Routes.ResidentPayment
            }
        },
        suggestionLabel(): string {
            return this.$_resident?.enableSuggestion ? `Oui` : `Non`
        }
    },

    async mounted() {
        if (this.canEditTutor) {
            const isInstitutionsRegistered = this.$store.state.dataInstitutions.isRegistered
            const isTutorsRegistered = this.$store.state.dataTutors.isRegistered

            try {

                //Get institutions if it isn't registered
                if (!isInstitutionsRegistered) {
                    await this.$store.dispatch('dataInstitutions/getInstitutions')
                    await this.$store.dispatch('dataInstitutions/setInstitutionsIsRegistered', true)
                }

                //Get tutors if it isn't registered
                if (!isTutorsRegistered) {
                    await this.$store.dispatch('dataTutors/getTutors', {
                        institutionId: this.$_currentInstitutionId
                    })
                }

                await this.$_resident

                if (this.$_resident?.userInChargeOfResident?.id) {
                    this.tutorSelectedId = this.$_resident.userInChargeOfResident.id
                }
                const formData = this.getFormById(Forms.NewReferant)
                if (formData) {
                    this.tutorSelectedId = formData.id
                    this.isChangesAreSaved = false
                }
                this.tutorIdBackup = this.tutorSelectedId
                console.log(this.$_resident)
            }
            catch(error) {
                console.error(error)
            }
        }
    },

    beforeRouteLeave (to, from, next) {
        if (this.isChangesAreSaved) {
            next()
            return
        }
        this.$vs.dialog({
            type: 'confirm',
            color: 'primary',
            title: `Annuler les modifications`,
            text: `Êtes-vous sûr de vouloir quitter cette page sans enregistrer ? Toutes les données renseignées seront perdues.`,
            acceptText: `Oui`,
            cancelText: `Non`,
            accept: () => {
                this.deleteReferantForm()
                next()
            },
            cancel: () => {
                next(false)
            }
        })
    },

    methods: {
        onTutorChange (tutorId: string): void {
            if (tutorId == 'create') {
                this.isChangesAreSaved = true
                this.$router.push({
                    name: Routes.NewReferant
                })
                return
            }
            this.tutorId = tutorId
            this.isChangesAreSaved = this.tutorIdBackup === this.tutorId
        },
        onClickBtnSaveTutor(): void {
            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: `Confirmation`,
                text: `Etes vous sûr de vouloir confirmer votre choix ?`,
                acceptText: `Oui`,
                cancelText: `Non`,
                accept: this.attachTutor
            })
        },
        showError(error: string): void {
            this.showErrorToast(`Une erreur s'est produite (${error}).`)
        },
        async deleteShoppingList(shoppingListId: string): Promise<void> {
            try {
                await this.$store.dispatch('dataPcps/deletePcp', {
                    pcpId: shoppingListId,
                    userId: this.$_currentUserId
                })
            }
            catch (error) {
                this.showError(error)
            }
        },
        async deactivateResident(): Promise<void> {
            try {
                await this.$store.dispatch('dataResidents/deactivateResident', {
                    residentId: this.$_residentId,
                    userId: this.$_currentUserId
                })
            }
            catch (error) {
                this.showError(error)
            }
        },
        async onDeactivateBtnClick() {
            try {
                this.residentStatus = await this.$store.dispatch('dataResidents/getResidentDeactivateStatus', {
                    residentId: this.$_residentId,
                    userId: this.$_currentUserId
                })
                this.deactivateResidentOpen = true
            }
            catch (error) {
                this.showError(error)
            }
        },
        async refreshResidentStatistics() {
            // Refresh residents store list to remove current basket link
            await this.$store.dispatch('dataResidents/getResidents', {
                userId: this.$_currentUserId,
                institutionId: this.$_currentInstitutionId
            })
            // Refresh user current orders (for left menu statistics)
            await this.$store.dispatch('dataOrders/getCurrentOrders', {
                userId: this.$_currentUserId,
                institutionId: this.$_currentInstitutionId
            })
            // Refresh user current baskets (for left menu statistics)
            await this.$store.dispatch('dataBaskets/getUserBaskets', {
                userId: this.$_currentUserId,
                institutionId: this.$_currentInstitutionId
            })
            // Hide page loading
            this.$vs.loading.close()
        },
        async onDeactivateResident() {
            try {
                const {
                    hasPendingOrder,
                    shoppingListId
                }: any = this.residentStatus

                // If a shopping list found
                if (hasPendingOrder && shoppingListId) {
                    // Show page loading
                    this.$vs.loading()
                    // Deleting resident shopping list (subscription)
                    await this.deleteShoppingList(shoppingListId)

                    // Refresh all statistics
                    await this.refreshResidentStatistics()

                    this.showInfoToast(`L'abonnement du résident a bien été supprimé`)
                }
                // Deactivate current resident
                if (!hasPendingOrder) {
                    // Show page loading
                    this.$vs.loading()

                    await this.deactivateResident()

                    // Refresh all statistics
                    await this.refreshResidentStatistics()

                    this.showInfoToast(`Le résident a bien été désactivé`)

                    // Redirect user to residents list page
                    this.$router.push({
                        name: Routes.Residents
                    })
                }
            }
            catch (error) {
                this.showError(error)
            }
        },
        getFormById (formId: string): Form {
            return this.$store.getters['dataForms/getFormById'](formId)
        },
        async attachTutor () {
            if (!this.$_resident) {
                return
            }
            try {
                await this.$store.dispatch('dataResidents/updateTutor', {
                    residentId: this.$_residentId,
                    tutor: this.tutorId ? {
                        id: this.tutorId
                    } : null
                })
                this.isChangesAreSaved = true
                this.deleteReferantForm()
                const message = this.tutorId ?
                `Le tuteur a bien été rattaché.` :
                `Le tuteur a bien été supprimé.`
                this.showInfoToast(message)
            }
            catch (error) {
                this.showError(error)
            }
        },
        deleteReferantForm () {
            this.$store.commit('dataForms/DELETE_FORM', {
                formId: Forms.NewReferant
            })
        },
        showInfoToast (text: string) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : 5000,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e: Event, to: ToastObject) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        showErrorToast (text: string) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e: Event, to: ToastObject) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    }
})
