import Vue from 'vue'
import { IUserDto } from '@happytal/happydom-api'

interface User extends IUserDto {
    role: string
    displayName?: string
    displayRole: string
    locale: string
}

export default Vue.extend({
    computed: {
        $_currentUser(): User | null {
            return this.$store.state.dataUser.user || null
        },
        $_currentUserId(): string {
            return this.$_currentUser?.id || ''
        },
        $_currentUserRole(): string {
            return this.$_currentUser?.role || ''
        },
    }
})