




import Vue from 'vue'

export default Vue.extend({
    props: {
        color: String,
        opacity: Number,
        type: {
            type: String,
            default: 'full',
            validator: val => ['full', 'centered'].includes(val)
        }
    },

    computed: {
        style(): Record<string, string> {
            return {
                backgroundColor: this.color,
                opacity: `${this.opacity}`
            }
        }
    }
})
