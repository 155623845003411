<template>
    <div class="tutor-selector">
        <div class="d-flex mt-3">
            <hx-switch
                :disabled="disabled"
                class="link-switch"
                data-cy="tutor-selector-switch"
                v-model="tutorLinked"
            ></hx-switch> Rattacher à un tuteur ou un proche
        </div>
        <div class="mt-5" v-if="tutorLinked">
            <v-select
                :disabled="disabled"
                v-model="tutorSelected"
                closeOnSelect
                :clearable="false"
                :options="tutors"
                data-cy="tutor-selector-select"
                v-validate="'required'"
                name="rattachement"
                placeholder="Rechercher..."
            >
                <template slot="option" slot-scope="option">
                    <span :class="{ 'tutor-create-option': option.value == 'create' }">{{ option.label }}</span>
                </template>
                <span slot="no-options">Il semble que cette personne n'ait pas encore de compte.
                  Vous devez lui créer un compte.</span>
            </v-select>
            <div class="resident-contact" v-if="tutorSelected">
                <div class="header">Contact</div>
                <div class="informations">
                    <a class="contact-link phone" v-bind:href="phoneLink">{{ tutor.phoneNumber }}</a> -
                    <a class="contact-link email" v-bind:href="emailLink">{{ tutor.email }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import vSelect from 'vue-select'

import Roles from '@/constants/roles'

import {
  capitalize
} from '@/modules/strings'

export default {
    name: 'TutorSelector',
    props: {
        selected: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        vSelect
    },
    data () {
        return {
            tutorSelected: null,
            tutorLinked: false,
            tutor: {
                phoneNumber: '',
                email: ''
            }
        }
    },
    computed: {
        tutors () {
            return this.getResidentTutors()
        },
        phoneLink () {
            return `tel:${this.tutor.phoneNumber}`
        },
        emailLink () {
            return `mailto:${this.tutor.email}`
        }
    },
    methods: {
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getTutorById (tutorId) {
            return this.$store.getters['dataTutors/getTutorById'](tutorId)
        },
        getTutorTypeLabel (type) {
            switch (type) {
                case Roles.Tutor:
                    return 'Tuteur'
                case Roles.Family:
                    return 'Proche'
                default:
                    return '-'
            }
        },
        getTutorSelectItem (tutor) {
            if (tutor.type == 'create') {
                return {
                    label: '+ Créer un nouveau tuteur / proche',
                    value: 'create'
                }
            }
            const lastName = tutor.lastName ? tutor.lastName.toUpperCase() : '-'
            const firstName = tutor.firstName ? capitalize(tutor.firstName) : '-'
            const legend = this.getTutorTypeLabel(tutor.type)
            return {
                label: `${lastName} ${firstName} (${legend}) - ${tutor.email}`,
                value: tutor.id
            }
        },
        getResidentTutors () {
            var storeTutors = _.clone(this.$store.state.dataTutors.tutors)
            storeTutors = _.orderBy(storeTutors, [
              (tutor) => tutor.lastName.toLowerCase(),
              (tutor) => tutor.firstName.toLowerCase()
            ], ['asc', 'asc'])
            if (this.$aclChecker.some(this.$acl, [
                'isEhpadManager',
                'isHappytalEmployee',
                'isHappytalSupport'
            ])) {
                storeTutors.unshift({
                    type: 'create'
                })
            }
            return storeTutors.map((tutor) => {
                return this.getTutorSelectItem(tutor)
            })
        },
        updateValue (val) {
            const tutorId = val && this.tutorSelected ? this.tutorSelected.value : null
            const tutorStore = tutorId ? this.getTutorById(tutorId) : null
            if (tutorStore) {
                this.tutor.phoneNumber = tutorStore.phoneNumber
                this.tutor.email = tutorStore.email
            }
            this.$emit('change', tutorId)
        }
    },
    watch: {
        tutorSelected (val) {
            this.updateValue(val)
        },
        tutorLinked (val) {
            this.updateValue(val)
        },
        selected (val) {
            if (val) {
                const tutorStore = this.getTutorById(val)
                if (tutorStore) {
                    this.tutorSelected = this.getTutorSelectItem(tutorStore)
                    this.tutorLinked = true
                    this.tutor.phoneNumber = tutorStore.phoneNumber
                    this.tutor.email = tutorStore.email
                }
            }
            else {
                this.tutorSelected = null
                this.tutorLinked = false
            }
        }
    },
    mounted () {
        async.series([
                (callback) => {
                    if (!this.$store.state.dataTutors.isRegistered) {
                        this.$store.dispatch('dataTutors/getTutors', {
                            institutionId: this.getInstitutionId()
                        })
                        .then(() => {
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
                //console.log('component loaded')
            }
        )
    }
}
</script>

<style lang="scss">
.tutor-selector {

    .link-switch {
        margin: 0px 16px 0px 0px;
    }
    .vs__dropdown-option--highlight {
        background: rgba(var(--vs-primary), 1);
    }
    .vs__dropdown-option--highlight .tutor-create-option {
        color: white;
    }

    .tutor-option {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }
    .tutor-create-option {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: rgba(var(--vs-primary), 1);
    }
    .resident-contact {
        margin: 21px 0px 0px 0px;
        color: #2C2C2C;

        .header {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
        }
        .informations {
            margin: 7px 0px 0px 0px;

            .contact-link {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
}
</style>
