import Vue from 'vue'
import { Institution } from '@/types/index'

export default Vue.extend({
    computed: {
        $_currentInstitutionId(): number | null {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
    },

    methods: {
        $_getInstitutionById(institutionId: number): Institution {
            return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
        },
    },
})