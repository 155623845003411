<template>
    <HPopin
        :value="value"
        @input="close"
        width="medium"
        class="deactivate-resident-popin"
    >
        <template #header>
            Désactiver un résident
        </template>
        <div class="text-content">
            <p v-html="messageHtml"></p>
        </div>
        <template
            v-if="actionEnabled"
            #actions
        >
            <HBtn
                @click="onCancelBtnClick"
                style-type="secondary"
                class="cancel-btn"
                data-cy="cancel-btn"
            >Annuler</HBtn>
            <HBtn
                @click="onConfirmBtnClick"
                style-type="primary"
                class="confirm-btn"
                data-cy="confirm-btn"
            >{{ ctaLabel }}</HBtn>
        </template>
    </HPopin>
</template>

<script>
    import {
      HPopin,
      HBtn
    } from "@happytal/bo-ui-library"

    export default {
        name: 'DeactivateResidentPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            HPopin,
            HBtn
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            status: {
                type: Object
            }
        },
        computed: {
            actionEnabled () {
                return !(this.status.hasPendingOrder && !this.status.shoppingListId)
            },
            messageHtml () {
                const {
                    hasPendingOrder,
                    shoppingListId
                } = this.status
                if (hasPendingOrder) {
                    // Ticket #S2-2575
                    // Case a.i
                    if (!shoppingListId) {
                        return `Il n’est pas possible de désactiver ce résident,
                        car il a une commande en cours de traitement.<br />
                        Une fois sa commande clôturée,
                        vous pourrez procéder à la désactivation.`
                    }
                    // Case a.ii
                    else {
                        return `Il n’est pas possible de désactiver ce résident
                        car il a une commande en cours de traitement.<br />
                        Souhaitez-vous supprimer l’abonnement de ce
                        résident pour ne pas créer de futures commandes ?`
                    }
                }
                else {
                    // Case b
                    if (!shoppingListId) {
                        return `Vous êtes sur le point de désactiver ce résident.<br />
                        Il ne sera plus disponible dans la plateforme.<br />
                        Souhaitez-vous continuer ?`
                    }
                    // Case c
                    else {
                        return `Vous êtes sur le point de désactiver ce résident.
                        Il ne sera plus disponible dans la plateforme et son
                        abonnement sera supprimé.<br />
                        Souhaitez-vous continuer ?`
                    }
                }
            },
            ctaLabel () {
                if (this.status.hasPendingOrder && this.status.shoppingListId) {
                    return `Supprimer l'abonnement`
                }
                return `Confirmer`
            }
        },
        methods: {
            close () {
                this.$emit('input', false)
            },
            onCancelBtnClick (e) {
                this.close()
            },
            onConfirmBtnClick (e) {
                this.close()
                this.$emit('deactivate')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .deactivate-resident-popin::v-deep {

        .text-content {
            font-style: normal;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #000000;
        }
        .h-popin__dialog {
            min-height: 230px;
        }
        .h-popin__actions {

        }
    }
</style>
