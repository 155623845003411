













import mixins from 'vue-typed-mixins'

import residentMixin from '@/mixins/residentMixin'
import ResidentChangeMenu from '@/views/residents/components/ResidentChangeMenu.vue'

export default mixins(residentMixin).extend({
    components: {
        ResidentChangeMenu
    },
    props: {
        pageTitle: String,
        displayResidentMenu: {
            type: Boolean,
            default: true
        }
    }
})
