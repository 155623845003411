import Vue from 'vue'

import { IResidentDto } from '@happytal/happydom-api'

export default Vue.extend({
    computed: {
        $_residentId(): string {
            if (this.$store.state.dataResidents.residents.length === 1) {
                return this.$store.state.dataResidents.residents[0].id
            }
            return this.$route.params.resident_id || this.$store.state.dataResidents.currentResidentId || ''
        },
        $_resident(): IResidentDto {
            return this.$_residentId && this.$store.getters['dataResidents/getResidentById'](this.$_residentId) || null
        },
        $_residentName(): string {
            return this.$_resident && this.$store.getters['dataResidents/getResidentName'](this.$_residentId) || ''
        },
        $_residentRoomLabel(): string {
            return this.$_resident && this.$_resident.roomNumber || '' 
        }
    }
})