



























import { noop } from 'lodash'
import mixins from 'vue-typed-mixins'
import residentMixin from '@/mixins/residentMixin'

import { IResidentDto } from '@happytal/happydom-api'

import { HMenu, HMenuSearch, HMenuItem, HIcon } from '@happytal/bo-ui-library'

export default mixins(residentMixin).extend({
    components: {
        HMenu,
        HMenuSearch,
        HMenuItem,
        HIcon
    },

    data () {
        return {
            menuSearchValue: "",
            residentSelected: null as any,
        }
    },

    computed: {
        residents(): IResidentDto[] {
            return this.$store.state.dataResidents.residents
        },

        filteredResidents(): IResidentDto[] {
            if (this.residents.length) {
                return this.residents.filter( resident => {
                    const firstName = (resident.firstName as string).toLowerCase()
                    const lastName = (resident.lastName as string).toLowerCase()
                    const menuSearchValue = this.menuSearchValue.toLowerCase()
                    
                    return firstName.includes(menuSearchValue)  || lastName.includes(menuSearchValue)
                })
            }

            return []
        },

        isMultipleResidents(): boolean {
            return this.residents.length > 1
        },
    },

    methods: {
        clearMenuSearchValue(value: boolean): void {
            if (!value) {
                const sto = setTimeout( () => {
                    this.menuSearchValue = ""
                    clearTimeout(sto)
                }, 250)
            }
        },
        onClickMenuItem(resident: IResidentDto): void {
            this.$router.push({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                name: this.$route.name!,
                params: Object.assign(this.$route.params, { resident_id: resident.id })
            }).catch(noop)
        }
    },
})
